import type { Cue } from 'types';

export const parseVTT = (text: string) => {
  const segments = text.split(/\n\s*\n/);
  const filtered = segments.filter((seg: string) => {
    const trimmed = seg.trim();
    return !(trimmed.length === 0 || trimmed.indexOf('NOTE') > -1 || trimmed === 'WEBVTT');
  });

  const timeToSeconds = (timeStr: string) => {
    const [time, decimal = '0'] = timeStr.trim().split('.');
    const seconds = time
      .split(':')
      .reverse()
      .reduce((prev: TSFix, curr: TSFix, i: number) => prev + curr * 60 ** i, 0);
    return parseFloat(seconds + '.' + decimal);
  };

  const cleanupCue = (cue: string) => {
    const lines = cue.split('\n');

    // Removes the Cue Identifier
    const timestampIndex = lines.findIndex((l: string) => l.includes('-->'));
    const timestamp = lines[timestampIndex];
    const captions = lines.slice(timestampIndex + 1);
    return [timestamp, captions] as [string, string[]];
  };

  const cues = filtered.map((cue: string) => {
    const [timestamp, captions] = cleanupCue(cue);
    const [start, rest] = timestamp.split('-->');
    const [end] = rest.trim().split(' ');
    return {
      startSeconds: timeToSeconds(start),
      endSeconds: timeToSeconds(end),
      text: captions.map((v: string) => v.trim()).join('\n'),
    };
  });

  return cues;
};

export function formatTimestampFromSeconds(timeInSeconds: number) {
  let hours: string | number = Math.floor(timeInSeconds / 3600);
  let minutes: string | number = Math.floor((timeInSeconds - hours * 3600) / 60);
  let seconds: string | number = Number((timeInSeconds - hours * 3600 - minutes * 60).toFixed(3));

  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds?.toFixed(3)}` : seconds?.toFixed(3);

  return `${hours}:${minutes}:${seconds}`;
}

export const encodeVTT = (file: string | number | boolean) =>
  `data:text/vtt;charset=utf-8;base64,${btoa(unescape(encodeURIComponent(file)))}`;

const vttSegmentBuilder = (startTime: number, endTime: number, text = '') => {
  return `\n\n${formatTimestampFromSeconds(startTime)} --> ${formatTimestampFromSeconds(endTime)}\n${text}`;
};

export const buildVTT = (transcript: Cue[]) => {
  let vtt = 'WEBVTT';
  transcript
    .filter((cue: Cue) => cue.text.trim().length !== 0)
    .forEach((cue: Cue) => {
      // Remove line breaks.
      // https://texthandler.com/info/remove-line-breaks-javascript/
      const text = cue.text.replace(/(\r\n|\n|\r)/gm, ' ');
      vtt = vtt.concat(vttSegmentBuilder(cue.startSeconds, cue.endSeconds, text));
    });
  return vtt;
};

export const createDefaultVTT = (videoSeconds: number) => {
  const segmentSeconds = 5;
  const minVideoSeconds = videoSeconds || 1;
  const totalClips = Math.ceil(minVideoSeconds / segmentSeconds);
  const generateSegment = (clipNumber: number) => {
    // First segment.
    if (clipNumber === 0)
      return vttSegmentBuilder(0, segmentSeconds > minVideoSeconds ? minVideoSeconds : segmentSeconds);
    // Last segment.
    if (clipNumber + 1 === totalClips) {
      return vttSegmentBuilder(clipNumber * segmentSeconds, minVideoSeconds);
    }
    // Middle segment.
    return vttSegmentBuilder(clipNumber * segmentSeconds, clipNumber * segmentSeconds + segmentSeconds);
  };
  let vtt = 'WEBVTT';
  for (let i = 0; i < totalClips; i += 1) {
    vtt = vtt.concat(generateSegment(i));
  }
  return vtt;
};
