import { createContext } from 'react';

import type { SetStateAction } from 'react';
import type { VideoMethods } from 'types';

type PlayerContextTypes = {
  autoAdvance: boolean;
  currentTime: number;
  initialVtt?: string;
  loadingTranscript?: boolean;
  setAutoAdvance: (topics: SetStateAction<boolean>) => void;
  setCurrentTime: (currentTime: number) => void;
  setInitialVtt: (vtt?: string) => void;
  setLoadingTranscript: (loadingTranscript?: boolean) => void;
  setVideoMethods: (videoMethods: VideoMethods) => void;
  setVtt: (vtt?: string) => void;
  videoMethods?: VideoMethods;
  vtt?: string;
};

const initialPlayerContextValues = {
  autoAdvance: true,
  currentTime: 0,
  initialVtt: undefined,
  loadingTranscript: undefined,
  setAutoAdvance: () => {},
  setCurrentTime: () => {},
  setInitialVtt: () => {},
  setLoadingTranscript: () => {},
  setVideoMethods: () => {},
  setVtt: () => {},
  videoMethods: undefined,
  vtt: undefined,
};

export default createContext(initialPlayerContextValues as PlayerContextTypes);
